import React, { useState } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Slider from "react-slick"

import { FadeIn } from "../Animations"
import CursorPicNext from "../../images/CursorPicNext.svg"
import CursorPicPrevious from "../../images/CursorPicPrevious.svg"
import { FixedButton } from "../FixedButton"

import PictureDesktop from "./PictureDesktop"

import "./slick.css"
import "./slick-theme.css"

const StyledPictures = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 10;
  font-family: kanit;

  opacity: 0;
  animation: ${FadeIn} 1s 0.4s linear forwards;
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  color: black;
  /*     padding-left:calc( 100vw - calc( calc(123.1vh )/2 ))!important;
 */

  @media (min-aspect-ratio: 3/2) {
    h3 {
      .title {
        text-align: right;
        display: inline-block;
      }
      align-self: left;
      font-weight: 800;
      font-size: 1.6vh;
      line-height: 2.5;
      padding: 0;
      margin: 0;
    }
    .landscape {
      .title {
        width: calc(calc(100vw - 102.5vh) / 2) !important;
        text-align: right;
      }
      .number {
        padding-left: 12vh;
        display: inline-block;
      }
    }

    .portrait {
      .title {
        width: calc(calc(100vw - 66vh) / 2) !important;
        text-align: right;
      }
      .number {
        padding-left: 8vh;
        display: inline-block;
      }
    }
  }
  .pic-container {
    max-height: 90vh !important;
    @media (min-aspect-ratio: 3/2) {
      padding-bottom: 8.5vh;
    }
    }

    @media (max-aspect-ratio: 3/2) {
      h3 {
        display: none;
      }
    }

    .slick-slider {
      display: none;
      @media (min-width: 800px) {
        display: block;
      }
    }
    .slick-next {
      cursor: url(${CursorPicNext}), auto !important;
      z-index: 99;
    }
    .slick-prev {
      cursor: url(${CursorPicPrevious}), auto !important;
      z-index: 99;
    }
  }
`

export default function PicturesDesktop(props) {
  const [picNumber, setNumber] = useState(4)

  const settings = {
    dots: true,
    infinite: true,
    fade: true,
    speed: 800,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <StyledPictures>
      <Slider {...settings}>
        <div className="pic-container">
          <h3 className="portrait">
            <span className="title"></span>
            <span className="number">{picNumber - 3}</span>
          </h3>
          <PictureDesktop filename="img3.jpg" />
        </div>
        <div className="pic-container">
          <h3 className="portrait">
            <span className="title"></span>
            <span className="number">{picNumber - 2}</span>
          </h3>
          <PictureDesktop filename="img1.jpg" />
        </div>
        <div className="pic-container">
          <h3 className="portrait">
            <span className="title"></span>
            <span className="number">{picNumber - 1}</span>
          </h3>
          <PictureDesktop filename="img2.jpg" />
        </div>
      </Slider>
      <FixedButton primary>
        <Link to="/">Back</Link>
      </FixedButton>
    </StyledPictures>
  )
}
